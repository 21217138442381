import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper/modules";
import "swiper/css/navigation";

import "./SwiperCarousel.css";

// Import all images
import image1 from "../assets/SW1.jpg";
import image2 from "../assets/SW2.jpg";
import image3 from "../assets/SW3.jpg";
import image4 from "../assets/SW4.jpg";
import image5 from "../assets/SW5.jpg";
import image6 from "../assets/SW6.jpg";
import image7 from "../assets/SW7.jpg";
import image8 from "../assets/SW8.jpg";
import image9 from "../assets/SW9.jpg";
import image10 from "../assets/SW10.jpg";
import image11 from "../assets/SW11.jpg";
import image12 from "../assets/SW12.jpg";
import image13 from "../assets/SW13.jpg";
import image14 from "../assets/SW14.jpg";

function SwiperCarouselHome() {
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
  ];

  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={10}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }}
      modules={[Pagination]}
      className="mySwiper"
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <img
            className="foto"
            src={image}
            alt={`russo-abbigliamento-collezione-${index + 1}`}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default SwiperCarouselHome;
