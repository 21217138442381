import React, { useRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import "./Home.css";
import Heroone from "./Heroone";
import Herotwo from "./Herotwo";
import Herothree from "./Herothree";
import Button from "./Button";
import CookieBanner from "./CookieBanner";
import { posthog } from "posthog-js";
import videoBg from "../assets/videoBG.mp4";
import videoMobile from "../assets/videoMobile.mp4";
import Video from "./Video";
import SwiperCarouselHome from "./SwiperCarouselHome";
import { useMediaQuery } from "react-responsive";

const homeContainerStyle = {
  width: "100%",
  minHeight: "120vh",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function Home() {
  const homeRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const handleVideoLoad = () => {
    setIsVideoLoaded(true);
  };

  return (
    <div className="home-container" ref={homeRef}>
      <Helmet>
        <title>Home - Russo Abbigliamento</title>
        <meta
          name="description"
          content="Vieni a scoprire la nostra nuova collezione primavera estate 2023"
        />
        <meta
          name="keywords"
          content="abbigliamento, collezione, primavera, estate, 2023, abbigliamento uomo, maglieria, camiceria, pantaloni, capispalla, Made in Italy, sartoriale, abiti"
        />
      </Helmet>

      <a href="#banner">
        <FontAwesomeIcon
          icon={faAngleDoubleDown}
          className="double-arrow-icon"
        />
      </a>

      <div style={homeContainerStyle}>
        <Video
          onLoadedData={handleVideoLoad}
          className={`background-video ${isVideoLoaded ? "loaded" : ""}`}
          video={isMobile ? videoMobile : videoBg}
        />
      </div>

      <div className="banner" id="banner">
        <h1>Scopri la nuova collezione FW 24/25</h1>
        <Button />
      </div>
      <Heroone />
      <Herotwo />
      <Herothree />

      <h1 className="carousel-header">Campaign FW24/25</h1>

      <SwiperCarouselHome />

      {posthog.has_opted_out_capturing() ||
      posthog.has_opted_in_capturing() ? null : (
        <CookieBanner />
      )}
    </div>
  );
}

export default Home;
